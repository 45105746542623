























































import {Component, Vue, Watch} from "vue-property-decorator";
import Brand from "@/models/Brand";
import BrandService from "@/services/BrandService";
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";
import Handler from "@/handlers/Handler";

@Component
export default class BrandsView extends Vue {
	lang: any = getModule(LangModule).lang
	loading: boolean = false

	page: number = 1
	search: string = ""
	pageCount: number = 0
	brands: Brand[] = []
	totalPages = Math.ceil(this.brands.length / 10)
	headers = [
		{text: "Id", value: "id", width: "10%", align: "center"},
		{text: this.lang.name, value: "name", width: "auto", align: "center"},
		{text: this.lang.creationDate, value: "createdAt", width: "auto", align: "center"},
	]

	created() {
		this.getBrands()
	}

	getBrands() {
		BrandService.getBrands(this, this.brands)
	}

	async synchronizeBrands() {
		await BrandService.synchronizeBrand()
	}

	@Watch('loading')
	onLoadingChanged() {
		this.totalPages = Math.ceil(this.brands.length / 10)
	}
}
